<template>
    <!-- voordelen -->
    <footer>
        <div class="text-gray-600 body-font before:bg-blue text-white bg-blue-900  pb-10">
            <div class="max-w-7xl mx-auto px-0 sm:px-4 mb-4">
                <div class="grid md:grid-cols-3 grid-cols-1 pt-7  cms-footer-content">
                    <div class="">

                        <slot name="content_website_footer_left">
                        </slot>
                    </div>


                    <div class="">
                        <slot name="content_website_footer_center">
                        </slot>
                    </div>

                    <div class="">
                        <slot name="content_website_footer_right">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-gray-600 body-font bg-indigo-600  text-white  text-sm md:text-[14px]">
            <div class="max-w-7xl mx-auto 4">
                <div class="px-4 p-3 text-center md:text-left w-full md:w-1/2 clear-both md:float-left">

                    &copy; &nbsp;<a :href="'https://www.'+website.current_domain">{{new Date().getFullYear()}} {{website.name}} alle rechten voorbehouden </a>&nbsp;
                </div>
                <div class=" px-4  p-3 float-right w-full md:w-1/2 text-center md:text-right">
                    <span v-for="(item,key,index) in menu_items.footer_bottom" :key="index">
                        <template v-if="key!==0">&nbsp;&nbsp;|&nbsp;</template>
                        &nbsp;<inertia-link :href="'/'+item.page_url" :title="item.name" class="text-white hover:text-gray-100  whitespace-nowrap ">
                            {{ item.name }}
                        </inertia-link>
                    </span>

                </div>
                <div class="clear-both"></div>
            </div>
        </div>

    </footer>

</template>

<script>
export default {
    name: "Footer",
    components: {},
    props: {
        menu_items: Object,
        page: Object,
        website:Object,
    },

    data() {
        return {}
    },
}
</script>

<style scoped>

</style>
